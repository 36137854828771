import React from 'react'
import SectionHeader from '../components/text/SectionHeader';
import SectionText from '../components/text/SectionText';
import BoldSectionText from '../components/text/BoldSectionText';
import SubSectionHeader from '../components/text/SubSectionHeader';
import ThreeColumnImg from '../components/ThreeColumnImg';
import TwoColumnImg from '../components/TwoColumnImg';
import SingleColumnImg from '../components/SingleColumnImg';
import Footer from '../components/Footer';
import { FiArrowLeft } from 'react-icons/fi';
import './ClassManagement.css';

export default function ClassManagement(props) {
    const backDiv = {
        paddingTop: 50,
    }
    return (
        <div className='container'>
            <div style={backDiv} className="btnDiv">
                <button className="simpleBtn" onClick={() => {props.history.goBack()}}><FiArrowLeft /> back to UX/UI </button>
            </div>
            <SectionHeader>Project</SectionHeader>
            <SectionText>Create a class management app for independent fitness instructors.</SectionText>
            <SubSectionHeader paddingTop={40}>BACKGROUND</SubSectionHeader>
            <SectionText>HIGH Fitness instructors run their own classes independently. These instructors normally use a notes app to take attendance, and use binders to track paper punch passes.</SectionText>
            <SubSectionHeader paddingTop={40}>GOAL</SubSectionHeader>
            <SectionText>Create an app that will help these instructors manage their classes better so they can run their business more efficiently. </SectionText>
            <SubSectionHeader paddingTop={40}>MY RESPONSIBILITIES</SubSectionHeader>
            <SectionText>{'\u2022'} User Research</SectionText>
            <SectionText>{'\u2022'} Wireframing</SectionText>
            <SectionText>{'\u2022'} Prototyping</SectionText>
            <SectionText>{'\u2022'} Usability Testing</SectionText>
            <SectionText>{'\u2022'} UI Design</SectionText>
            <SingleColumnImg
                imgSrcOne={'ClassMan/HIGH-Gif.gif'} 
                srcOneAlt={'app interface design'}
                
            />
          
            <SectionHeader paddingTop={70}>User Research</SectionHeader>
            <SectionText>User research uncovered these 4 main problems:</SectionText>
            <SectionText>
                <BoldSectionText>Pain Point: </BoldSectionText>Tracking attendance was time consuming, and manually done.  {'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Create easier attendance records with stats on each client.
            </SectionText>
            <SectionText>
                <BoldSectionText>Pain Point: </BoldSectionText>Lost or duplicate punch passes create confusion and make payment a hassle. {'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Create a digital punch pass.
            </SectionText>
            <SectionText>
                <BoldSectionText>Pain Point: </BoldSectionText>With no consistent record keeping, business insights are lost, making growing a business difficult.{'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Create statistics and insights page as well as manage clientelle and classes.
            </SectionText>
            <SectionText>
                <BoldSectionText>Pain Point: </BoldSectionText>Payment is manually and individually tracked on venmo, making a time consuming process to ensure each member has paid{'\n\r'}
                <BoldSectionText>Goal: </BoldSectionText>Connect with Venmo API to retrieve member payment interactions. 
            </SectionText>

            <SubSectionHeader paddingTop={40}>PERSONAS</SubSectionHeader>
            <TwoColumnImg
                imgSrcOne={'ClassMan/HIGH_Mila.jpg'}
                imgSrcTwo={'ClassMan/HIGH_Kia.jpg'}
                srcOneAlt={'Mila Persona'}
                srcTwoAlt={'Kia Persona'}
            />

            <SubSectionHeader paddingTop={40}>USER JOURNEY MAPS</SubSectionHeader>
            <SectionText>I then created user journey maps for Mila and Kia. It was important to understand the context in which this app would be used, and the problems they faced.</SectionText>
            <SectionText>For Mila, punch passes are often misplaced, lost, or missing. She wants to better market to those that haven't come in a while, but taking notes on who is there is time consuming.</SectionText>
            <SectionText>For Kia, a lack of attendance records means she doesn't have any business insights needed to grow and manage her clientelle and classes.  </SectionText>
            <SingleColumnImg
                imgSrcOne={'ClassMan/HIGH_UserJourneyMap.jpg'}
                srcOneAlt={'persona user journey maps'}
               propClass={'fullimg'}
            />

            <SectionHeader paddingTop={70}>Ideate</SectionHeader>
            <SubSectionHeader paddingTop={40}>COMPETITIVE AUDIT</SubSectionHeader>
            <SectionText>There are several attendance apps that offer attendance taking for academicians, instructors, and small business owners. I compiled a competitive audit of 4 competitors. I listed pros and cons and gave ratings for their features, accessibility, navigation and visual design. After analyzing strengths and weaknesses of each, this gave me a good idea of gaps and opportunities for my class management app.</SectionText>
            <div className='LinkBtnWrapper'>
            <a className='LinkBtn' href="https://docs.google.com/spreadsheets/d/1ooxSgjsXjJadFJlMhiv0PZVLZYbTRk95UUTjHkIABQ8/edit?usp=sharing" target="_blank" rel="noopener noreferrer"> 
            Competitive Audit
            </a>
            </div>
            <SectionText paddingTop={70}><BoldSectionText>Opportunity Gap:</BoldSectionText></SectionText>
            <SectionText>{'\u2022'} Simplify interface (consider limited nested screens and utilize drop down menus for organization)</SectionText>
            <SectionText>{'\u2022'} Create member profiles (include digital punch passes, classes they attend, payment logs, attendance records and stats)</SectionText>
            <SectionText>{'\u2022'} Create encouraging toast messages that are member-specific stats ("It's Sarah's 100th class!") and instructor-specific stats ("This is your largest class size this year!") </SectionText>
            <SectionText>{'\u2022'} Consider having the app automatically sort active and inactive members, so member lists are managed better</SectionText>
            <SectionText>{'\u2022'} Position the app toward the fitness industry, specifically HIGH fitness instructors. </SectionText>


            <SubSectionHeader paddingTop={40}>USER FLOW</SubSectionHeader>
            <SectionText>I started thinking about the user flow and what choices, actions, or decisions a user must take to complete certain tasks. This helped me understand where the navigational flow can be improved.</SectionText>
            <SingleColumnImg
                imgSrcOne={'ClassMan/HIGH_UserFlow.jpg'}
                srcOneAlt={'user flow'}
               propClass={'fullimg'}
            />

   
           
            <SectionHeader paddingTop={70}>Wireframe</SectionHeader>
            <SectionText>I created 5 paper wireframes of each main screen. I then starred elements on each wireframe that I liked, and created a sixth version of my starred elements in one screen design.
</SectionText>
            <SingleColumnImg
                imgSrcOne={'ClassMan/HIGH_PaperWireframe.jpg'}
                srcOneAlt={'paper wireframe sketches'}
               
            />
    
            <SectionText>As I started digitizing the paper wireframes, I noticed some elements needed to be reworked and revised. It was helpful to begin placing copy for more context, and grayscale colors to bring visual weight on the screen.</SectionText>
            <ThreeColumnImg
                imgSrcOne={'ClassMan/HIGH_Wireframe-A.jpg'}
                imgSrcTwo={'ClassMan/HIGH_Wireframe-B.jpg'}
                imgSrcThree={'ClassMan/HIGH_Wireframe-C.jpg'}
                srcOneAlt={'main screen wireframe'}
                srcTwoAlt={'member information screen wireframe'}
                srcThreeAlt={'attendance screen wireframe'}
            />


            <SectionHeader paddingTop={70}>Usability Study: Round 1 Findings</SectionHeader>
            <SectionText>I conducted 5 usability tests. Each test was held remotely and moderated. Users were asked to perform a set of tasks with the low-fidelity prototype. Below are themes and insights from these tests.</SectionText>
<           SectionText>
                <BoldSectionText>Observation: </BoldSectionText>Difficulty and confusion adding a class. Users expected drop down to have 'add a class.'{'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText>Users need a more intuitive way to access this feature. The drop down didn't contain desired/assumed actions
            </SectionText>
            <SectionText>
                <BoldSectionText>Observation: </BoldSectionText>Hesitancy finding a members punch pass {'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText>Member names need to look and feel more like an actionable/clickable button
            </SectionText>
            <SectionText>
                <BoldSectionText>Observation: </BoldSectionText>Questioned relevance of 3-dot menu {'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText>The three dot menu may need to be reevaluated or menu items dispersed elsewhere
            </SectionText>
            <SectionText>
                <BoldSectionText>Observation: </BoldSectionText> Users couldn't add a member while taking attendance {'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText> Users wanted to be able to ‘add a member’ while taking attendance
            </SectionText>
        
            <div className='LinkBtnWrapper'>
            <a className='LinkBtn' href="https://www.figma.com/proto/5hGRtMiXoAE95ZBDpqLZlh/Highlife?node-id=56%3A3&scaling=scale-down&page-id=56%3A2&starting-point-node-id=56%3A3" target="_blank" rel="noopener noreferrer"> 
            View Low-Fi Prototype
            </a>
            </div>
                
            <SubSectionHeader paddingTop={70}>REVISED DESIGNS</SubSectionHeader>
            <SectionText>
            <BoldSectionText>Insight: </BoldSectionText> Users need a more intuitive way to access ‘Add a Class’ feature. Most users assumed ‘Add a Class’ would be in the drop down menu.</SectionText>
            <TwoColumnImg
                imgSrcOne={'ClassMan/HIGH_Before-A.jpg'}
                imgSrcTwo={'ClassMan/HIGH_After-A.jpg'}
                srcOneAlt={'before usabilty study screenshot'}
                srcTwoAlt={'after usability study screenshot'}
            />

            <SectionText paddingTop={100}>
            <BoldSectionText>Insight: </BoldSectionText> Make member names appear more like a clickable / actionable button</SectionText>

            <TwoColumnImg
                imgSrcOne={'ClassMan/HIGH_Before-B.jpg'}
                imgSrcTwo={'ClassMan/HIGH_After-B.jpg'}
                srcOneAlt={'before usabilty study screenshot'}
                srcTwoAlt={'after usability study screenshot'}
            />

            <SectionText paddingTop={100}>
            <BoldSectionText>Insight: </BoldSectionText> Users questioned relevance/purpose of 3-dot menu. </SectionText>

            <TwoColumnImg
                imgSrcOne={'ClassMan/HIGH_Before-C.jpg'}
                imgSrcTwo={'ClassMan/HIGH_After-C.jpg'}
                srcOneAlt={'before usabilty study screenshot'}
                srcTwoAlt={'after usability study screenshot'}
            />

            <SectionText paddingTop={100}>
            <BoldSectionText>Insight: </BoldSectionText> Users wanted to be able to ‘add a member’ while taking attendance </SectionText>

            <TwoColumnImg
                imgSrcOne={'ClassMan/HIGH_Before-D.jpg'}
                imgSrcTwo={'ClassMan/HIGH_After-D.jpg'}
                srcOneAlt={'before usabilty study screenshot'}
                srcTwoAlt={'after usability study screenshot'}
            />

            <SectionHeader paddingTop={70}>Design</SectionHeader>  
            <SectionText>After implementing usability test insights, it was time for design. I wanted a modern look and feel that felt deluxe, but with a hint of playful color. I chose a sans serif typeface that feels modern and approachable. The youthful bubble gum pink balances out the rich and lush dark mode, adding a peek of light-hearted fun. I began creating a stickersheet of colors, typography, icons, navigation, components, and their states. </SectionText>

            <SingleColumnImg
                imgSrcOne={'ClassMan/HIGH_Sticker1.jpg'}
                srcOneAlt={'sticker sheet colors, fonts, typography'} 
                propClass={'fullimg'}
            />

            <SingleColumnImg
                imgSrcOne={'ClassMan/HIGH_Sticker2.jpg'}
                srcOneAlt={'sticker sheet components'}
                propClass={'fullimg'}
            />

            <SectionHeader paddingTop={70}>Usability Study: Round 2 Findings</SectionHeader>
            <SectionText>Round 2 of usabilty tests were conducted remotely and were moderated. Users were asked to perfom a set of tasks with the high-fidelity prototype. Below are themes and insights from these tests.</SectionText>
<           SectionText>
                <BoldSectionText>Observation: </BoldSectionText>Can’t edit class shown as drop down title{'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText> Add ‘edit class’ functionality to the class currently shown in the drop down title. 
            </SectionText>
            <SectionText>
                <BoldSectionText>Observation: </BoldSectionText>Confusion with punch pass colors (punched vs unpunched){'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText>Punch pass needs to have clear indication of what has been already punched
            </SectionText>
            <SectionText>
                <BoldSectionText>Observation: </BoldSectionText>Users wanted a way to edit attendance after it was recorded {'\n\r'}
                <BoldSectionText>Insight: </BoldSectionText>Add Edit attendance functionality to attendance records that have already been recorded.
            </SectionText>

            <div className='LinkBtnWrapper'>
                <a className='LinkBtn' href="https://www.figma.com/proto/5hGRtMiXoAE95ZBDpqLZlh/Highlife?node-id=156%3A1505&scaling=scale-down&page-id=156%3A1504&starting-point-node-id=156%3A1505" target="_blank" rel="noopener noreferrer"> 
            View High-Fi Prototype
            </a>
            </div>

            <SubSectionHeader paddingTop={70}>REVISED DESIGNS</SubSectionHeader>
            <SectionText><BoldSectionText>Insight: </BoldSectionText> Add ‘edit class’ functionality to the class currently shown in the drop down title.</SectionText>
            <TwoColumnImg
                imgSrcOne={'ClassMan/HIGH_Before-E.jpg'}
                imgSrcTwo={'ClassMan/HIGH_After-E.jpg'}
                srcOneAlt={'before usabilty study screenshot'}
                srcTwoAlt={'after usability study screenshot'}
            />

        <SectionText paddingTop={100}><BoldSectionText>Insight: </BoldSectionText> Punch pass needs to have clear indication of what has been already punched</SectionText>
            <TwoColumnImg
                imgSrcOne={'ClassMan/HIGH_Before-F.jpg'}
                imgSrcTwo={'ClassMan/HIGH_After-F.jpg'}
                srcOneAlt={'before usabilty study screenshot'}
                srcTwoAlt={'after usability study screenshot'}
            />

        <SectionText paddingTop={100}><BoldSectionText>Insight: </BoldSectionText> Users want to Edit Attendance from attendance records that have already been recorded</SectionText>
            <TwoColumnImg
                imgSrcOne={'ClassMan/HIGH_Before-G.jpg'}
                imgSrcTwo={'ClassMan/HIGH_After-G.jpg'}
                srcOneAlt={'before usabilty study screenshot'}
                srcTwoAlt={'after usability study screenshot'}
            />

            <SectionHeader paddingTop={70}>Key Takeaway</SectionHeader>
            <SectionText>{'\u2022'} Always let users go back and edit/delete/rename/move content. Users need this flexibility. </SectionText>
            <SectionText>{'\u2022'} Referencing skeuomorphs can be insightful. The pink punch pass circles were not only very ambigious on what has been punched, but didn't reflect what happens to a physical paper punch people are commonly used to.</SectionText>
            <SectionText>{'\u2022'} Reference other design systems. Rather than just a check in a checkbox, I noticed Microsofts design system highlighted the row with a stroke and light opacity rectangle. I used this same method in my own design. It feels more delightful and satisfying to complete this action.</SectionText>

           
            <SubSectionHeader paddingTop={50}>NEXT STEPS</SubSectionHeader>
            <SectionText>Create Light Mode design for increased accessibility and conduct field research.</SectionText>
            <SectionText><BoldSectionText>Field research questions:</BoldSectionText></SectionText>
            <SectionText>{'\u2022'} How long does it take to complete attendance?</SectionText>
            <SectionText>{'\u2022'} How often do users add a member while taking attendance? What insights can we learn from to make that a more efficient user flow?</SectionText>
            <SectionText>{'\u2022'} How often are users interrupted from taking attendance (requiring them to navigate to a different screen)? What insights can we learn from to make that a more efficient user flow?</SectionText>

           <Footer></Footer>
        </div>
    )
}
