import React from 'react'
import SectionHeader from '../components/text/SectionHeader';
import { Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './UxUi.css';

export default function UxUi() {

    return (
        <div className='container'>
            <div className="infographics top">
                <div className="title">
                    <Dropdown>
                        <Dropdown.Toggle className="dropdownTitle">
                            UX/UI
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <LinkContainer to="/branding">
                                <Dropdown.Item >BRANDING</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/motion">
                                <Dropdown.Item>MOTION</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/invitations">
                                <Dropdown.Item>INVITATIONS</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/infographics">
                                <Dropdown.Item>INFOGRAPHICS</Dropdown.Item>
                            </LinkContainer>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="uxMainContainer">
                <div className="uxContainer" >
                    <div className="">
                        <SectionHeader textAlign={'center'}>Class Management</SectionHeader>
                        <LinkContainer to="/classManagement">
                            <img className="imgHover image" onClick={() => { }} alt={'go to class management ux ui page'} src={require('../media/ClassMan/HIGH_FeatureThumb.jpg')} />
                        </LinkContainer>
                        <div className="middleInfo">
                            <div className="text" onClick={() => { }}>Click to see Class Management UX/UI</div>
                        </div>
                    </div>
                </div>
                <div className="uxContainer" >
                    <div className="">
                        <SectionHeader textAlign={'center'}>Creative Prompts</SectionHeader>
                        <LinkContainer to="/CreativePrompts">
                            <img className="imgHover image" onClick={() => { }} alt={'go to creative prompts ux ui page'} src={require('../media/CreativePrompts/CreativePrompts_FeatureThumb.jpg')} />
                        </LinkContainer>
                        <div className="middleInfo">
                            <div className="text" onClick={() => { }}>Click to see Creative Prompts UX/UI</div>
                        </div>
                    </div>
                </div>
                <div className="uxContainer" >
                    <div className="">
                        <SectionHeader textAlign={'center'}>Climbing Partner Finder</SectionHeader>
                        <LinkContainer to="/ClimbingPartnerFinder">
                            <img className="imgHover image" onClick={() => { }} alt={'go to climbing partner ux ui page'} src={require('../media/ClimbingPartner/ClimbingPartner_FeatureThumb.jpg')} />
                        </LinkContainer>
                        <div className="middleInfo">
                            <div className="text" onClick={() => { }}>Click to see Climbing Partner Website UX/UI</div>
                        </div>
                    </div>
                </div>
                <div className="uxContainer" >
                    <div className="">
                        <SectionHeader textAlign={'center'}>Cooked</SectionHeader>
                        <LinkContainer to="/cookedUxUi">
                            <img className="imgHover image" onClick={() => { }} alt={'go to cooked ux ui page'} src={require('../media/Cooked/Cooked_FeatureThumb.jpg')} />
                        </LinkContainer>
                        <div className="middleInfo">
                            <div className="text" onClick={() => { }}>Click to see Cooked UX/UI</div>
                        </div>
                    </div>
                </div>
                <div className="uxContainer" >
                    <div className="">
                        <SectionHeader textAlign={'center'}>Honed</SectionHeader>
                        <LinkContainer to="/honedUxUi">
                            <img className="imgHover image" onClick={() => { }} alt={'go to honed ux ui page'} src={require('../media/Honed/Honed_FeatureThumb.jpg')} />
                        </LinkContainer>
                        <div className="middleInfo">
                            <div className="text" onClick={() => { }}>Click to see Honed UX/UI</div>
                        </div>
                    </div>
                </div>





            </div>
        </div>
    )
}
