import React from 'react'
import './SectionText.css';

export default function SectionText({children, paddingTop = 0, paddingBottom = 0,}) {

    const divStyle={
        paddingTop: paddingTop,
        paddingBottom: paddingBottom,
    }

    return (
        <div style={divStyle} className='sectionTextContainer'>
            <p className='sectionText'>{children}</p>
        </div>
    )
}
