import React, { Component } from "react";
import { connect } from 'react-redux';
import { navBackground } from '../actions/navBackground';
import { Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import "./About.css";

class About extends Component {

  componentDidMount() {
    this.props.navBackground(false);
  }

  render() {
    return (
      <div className="About">
        <Container>
          <Row>
            <Col xs={12} sm={4}>
              <div>
                <img className="aboutImg" src={require('../media/MaggieProfile.jpg')} alt="Maggie"></img>
              </div>
            <div className='TextLinkBtn'>
              <a className='TextLinkBtn' href="https://www.linkedin.com/in/maggiecall/" target="_blank" rel="noopener noreferrer"> 
            Connect on LinkedIn
            </a>
            </div>

                <div>
                <LinkContainer to="/usability">
                  <div className='hidden'></div>
                </LinkContainer>
              </div>
            </Col>
            <Col xs={12} sm={8}>
              <div className="aboutText">
                <h2>Maggie is a UX designer, monochrome lover, and ends almost every meal with chocolate.</h2>
                <br></br>
                <p> Maggie admires how tasteful design has the power to delight our everyday life. She loves working on projects that simplify our lives and excite our interactions in a meaningful way.</p>
                <br></br>
                <p>Maggie’s passion for minimalism inspires her to untangle complexities to simplify user experiences. She enjoys interaction design, visual design, and UX research. Maggie is passionate about accessible and inclusive design and is curiously learning about designing for diverse people and situations. Maggie values integrity both for herself and the workplace, and strives to practice upstanding character traits. </p>
                <br></br>
                <p>You can find Maggie reorganizing something (anything!), baking, or exploring her hand at painting abstract art. She frequently assists in one of her husband's many DIY projects around the house. Maggie enjoys rock climbing, snowshoeing and a solid 72% cocoa bar.</p>
                <br></br>
                <div className='LinkBtnWrapper'>
                <a className='LinkBtn' href="https://drive.google.com/file/d/1jlklC0MJ0WUNc8lT1JqN3OF0E4qwQAZR/view?usp=sharing" target="_blank" rel="noopener noreferrer"> 
            View Maggie's Resume
            </a>
            </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(null, { navBackground })(About);